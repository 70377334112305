import Vue from "vue";
import VueRouter from "vue-router";

let originPush = VueRouter.prototype.push;
let originReplace = VueRouter.prototype.replace;

VueRouter.prototype.push = function push(location) {
  return originPush.call(this, location).catch((err) => err);
};
VueRouter.prototype.replace = function push(location) {
  return originReplace.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/website",
  },
  {
    path: "/website",
    name: "website",
    component: (resolve) => require(["@/views/website/index"], resolve),
  },
  {
    path: "/about",
    name: "about",
    component: (resolve) => require(["@/views/website/about"], resolve),
  },
  {
    path: "/login",
    name: "login",
    component: (resolve) => require(["@/views/login/login"], resolve),
  },
  {
    path: "/forget",
    name: "forget",
    component: (resolve) => require(["@/views/login/forget"], resolve),
  },
  {
    path: "/register",
    name: "register",
    component: (resolve) => require(["@/views/login/register"], resolve),
  },
  {
    path: "/layout",
    name: "layout",
    component: (resolve) => require(["@/views/layout/layout"], resolve),
    children: [
      {
        path: "/index",
        name: "index",
        component: (resolve) => require(["@/views/index/index"], resolve),
      },
      {
        path: "/columns",
        name: "columns",
        component: (resolve) => require(["@/views/index/columns"], resolve),
      },
      {
        path: "/homeworkRank",
        name: "homeworkRank",
        component: (resolve) =>
          require(["@/views/index/homeworkRank"], resolve),
      },
      {
        path: "/homeworkList",
        name: "homeworkList",
        component: (resolve) =>
          require(["@/views/index/homeworkList"], resolve),
      },
      {
        path: "/footPrint",
        name: "footPrint",
        component: (resolve) => require(["@/views/index/footPrint"], resolve),
      },
      {
        path: "/themeDetail",
        name: "themeDetail",
        component: (resolve) => require(["@/views/index/themeDetail"], resolve),
      },
      {
        path: "/topicThemeList",
        name: "topicThemeList",
        component: (resolve) =>
          require(["@/views/index/topicThemeList"], resolve),
      },
      {
        path: "/checkin",
        name: "checkin",
        component: (resolve) => require(["@/views/index/checkin"], resolve),
      },
      {
        path: "/accountManagement",
        name: "accountManagement",
        component: (resolve) =>
          require(["@/views/accountManagement/accountManagement"], resolve),
      },
      {
        path: "/dashboard",
        name: "dashboard",
        component: (resolve) =>
          require(["@/views/dashboard/dashboard"], resolve),
      },
      {
        path: "/redirect_dashboard",
        redirect: "/dashboard",
      },
      {
        path: "/planetEdit",
        component: (resolve) =>
          require(["@/views/planetEdit/planetEdit"], resolve),
      },
      {
        path: "/home",
        name: "home",
        component: (resolve) => require(["@/views/home/home"], resolve),
        children: [
          {
            path: "/planetHome",
            name: "planetHome",
            component: (resolve) =>
              require(["@/views/planetHome/index"], resolve),
          },
          {
            path: "/contentManagement",
            name: "contentManagement",
            component: (resolve) =>
              require(["@/views/contentManagement/contentManagement"], resolve),
          },
          {
            path: "/statisticMember",
            name: "statisticMember",
            component: (resolve) =>
              require(["@/views/statisticMember/index"], resolve),
          },
          {
            path: "/statisticTheme",
            name: "statisticTheme",
            component: (resolve) =>
              require(["@/views/statisticTheme/index"], resolve),
          },
          {
            path: "/discountCoupon",
            name: "discountCoupon",
            component: (resolve) =>
              require(["@/views/operationFunction/discountCoupon"], resolve),
          },
          {
            path: "/columnsManagement",
            name: "columnsManagement",
            component: (resolve) =>
              require(["@/views/operationFunction/columnsManagement"], resolve),
          },
          {
            path: "/askManagement",
            name: "askManagement",
            component: (resolve) =>
              require(["@/views/operationFunction/askManagement"], resolve),
          },
          {
            path: "/homeworkManagement",
            name: "homeworkManagement",
            component: (resolve) =>
              require([
                "@/views/operationFunction/homeworkManagement",
              ], resolve),
          },
        ],
      },
      {
        path: "/withdraw",
        name: "withdraw",
        component: (resolve) => require(["@/views/withdraw/index"], resolve),
      },
      {
        path: "/product",
        name: "product",
        component: (resolve) => require(["@/views/product/product"], resolve),
      },
    ],
  },
  {
    path: "/adm",
    name: "adm",
    component: (resolve) => require(["@/adm/layout"], resolve),
    children: [
      {
        path: "/adm/dashboard",
        name: "admDashboard",
        component: (resolve) => require(["@/adm/dashboard/index"], resolve),
      },
      {
        path: "/adm/planet/list",
        name: "admPlanetList",
        component: (resolve) => require(["@/adm/planet/list"], resolve),
      },
      {
        path: "/adm/planet/course/video",
        name: "admPlanetCourse",
        component: (resolve) => require(["@/adm/planet/course"], resolve),
      },
      {
        path: "/adm/planet/course/audio",
        name: "admPlanetCourse",
        component: (resolve) => require(["@/adm/tools/404"], resolve),
      },
      {
        path: "/adm/planet/course/richtext",
        name: "admPlanetCourse",
        component: (resolve) => require(["@/adm/tools/404"], resolve),
      },
      {
        path: "/adm/planet/course/mixture",
        name: "admPlanetCourse",
        component: (resolve) => require(["@/adm/tools/404"], resolve),
      },
      {
        path: "/adm/planet/page",
        name: "admPlanetPage",
        component: (resolve) => require(["@/adm/planet/page"], resolve),
      },
      {
        path: "/adm/planet/channel",
        name: "admPlanetMenus",
        component: (resolve) => require(["@/adm/planet/channel"], resolve),
      },
      {
        path: "/adm/content/list",
        name: "admContentList",
        component: (resolve) => require(["@/adm/content/list"], resolve),
      },
      {
        path: "/adm/content/column",
        name: "admContentColumn",
        component: (resolve) => require(["@/adm/content/column"], resolve),
      },
      {
        path: "/adm/member/dashboard",
        name: "admMemberDashboard",
        component: (resolve) => require(["@/adm/member/dashboard"], resolve),
      },
      {
        path: "/adm/member/list",
        name: "admMemberList",
        component: (resolve) => require(["@/adm/member/list"], resolve),
      },
      {
        path: "/adm/member/tags",
        name: "admMemberTags",
        component: (resolve) => require(["@/adm/member/tags"], resolve),
      },
      {
        path: "/adm/trade/orderList",
        name: "admTradeOrderList",
        component: (resolve) => require(["@/adm/trade/orderList"], resolve),
      },
      {
        path: "/adm/orders/takeout",
        name: "admTradeOrderList",
        component: (resolve) => require(["@/adm/trade/takeout/index"], resolve),
      },
      {
        path: "/adm/scope/ttScope",
        name: "admScopeTtScope",
        component: (resolve) => require(["@/adm/scope/ttScope"], resolve),
      },
      {
        path: "/adm/scope/index",
        name: "admScopeIndex",
        component: (resolve) => require(["@/adm/scope/index"], resolve),
      },
      {
        path: "/adm/scope/roleApply",
        name: "admScopeRoleApply",
        component: (resolve) => require(["@/adm/scope/roleApply"], resolve),
      },
      {
        path: "/adm/scope/roleAuthInfoApply",
        name: "roleAuthInfoApply",
        component: (resolve) =>
          require(["@/adm/scope/roleAuthInfoApply"], resolve),
      },
      {
        path: "/adm/scope/roleBaseInfoApply",
        name: "roleBaseInfoApply",
        component: (resolve) =>
          require(["@/adm/scope/roleBaseInfoApply"], resolve),
      },
      {
        path: "/adm/scope/roleClassInfoApply",
        name: "roleClassInfoApply",
        component: (resolve) =>
          require(["@/adm/scope/roleClassInfoApply"], resolve),
      },
      {
        path: "/adm/scope/enterpriseBaseInfoApply",
        name: "enterpriseBaseInfoApply",
        component: (resolve) =>
          require(["@/adm/scope/enterpriseBaseInfoApply"], resolve),
      },
      {
        path: "/adm/scope/enterpriseClassInfoApply",
        name: "enterpriseClassInfoApply",
        component: (resolve) =>
          require(["@/adm/scope/enterpriseClassInfoApply"], resolve),
      },
      {
        path: "/adm/scope/enterpriseAuthInfoApply",
        name: "enterpriseAuthInfoApply",
        component: (resolve) =>
          require(["@/adm/scope/enterpriseAuthInfoApply"], resolve),
      },
      {
        path: "/adm/tools/coupon",
        name: "admToolsCoupon",
        component: (resolve) => require(["@/adm/tools/coupon"], resolve),
      },
      {
        path: "/adm/tools/distribution",
        name: "admToolsDistribution",
        component: (resolve) => require(["@/adm/tools/distribution"], resolve),
      },
      {
        path: "/adm/tools/limit",
        name: "admToolsLimit",
        component: (resolve) => require(["@/adm/tools/limit"], resolve),
      },
      {
        path: "/adm/tools/mount",
        name: "admToolsMount",
        component: (resolve) => require(["@/adm/tools/mount"], resolve),
      },
      {
        path: "/adm/tools/douyinGroup",
        name: "admToolsDouyinGroup",
        component: (resolve) => require(["@/adm/tools/douyinGroup"], resolve),
      },
      {
        path: "/adm/tools/wxGroup",
        name: "admToolsWxGroup",
        component: (resolve) => require(["@/adm/tools/wxGroup"], resolve),
      },
      {
        path: "/adm/tools/limitedTime",
        name: "admToolsLimitedTime",
        component: (resolve) => require(["@/adm/tools/404"], resolve),
      },
      {
        path: "/adm/tools/groupPurchases",
        name: "admToolsGroupPurchases",
        component: (resolve) => require(["@/adm/tools/404"], resolve),
      },
      {
        path: "/adm/tools/packets",
        name: "admToolsPackets",
        component: (resolve) => require(["@/adm/tools/404"], resolve),
      },
      {
        path: "/adm/tools/gift",
        name: "admToolsGift",
        component: (resolve) => require(["@/adm/tools/404"], resolve),
      },
      {
        path: "/adm/tools/live",
        name: "admToolsLive",
        component: (resolve) => require(["@/adm/tools/404"], resolve),
      },
      {
        path: "/adm/tools/poster",
        name: "admToolsPoster",
        component: (resolve) => require(["@/adm/tools/404"], resolve),
      },
      {
        path: "/adm/planet/detail",
        name: "planetDetail",
        component: (resolve) => require(["@/adm/planet/detail"], resolve),
        children: [
          {
            path: "/adm/planet/active",
            name: "planetActive",
            component: (resolve) => require(["@/adm/planet/active"], resolve),
          },
          {
            path: "/adm/planet/columns",
            name: "planetColumns",
            component: (resolve) => require(["@/adm/planet/columns"], resolve),
          },
          {
            path: "/adm/planet/cards",
            name: "planetCards",
            component: (resolve) => require(["@/adm/planet/cards"], resolve),
          },
          {
            path: "/adm/planet/cards/punchcardList",
            name: "planetPunchcardList",
            component: (resolve) =>
              require(["@/adm/planet/punchcardList"], resolve),
          },
          {
            path: "/adm/planet/homework",
            name: "planetHomework",
            component: (resolve) => require(["@/adm/planet/homework"], resolve),
          },
          {
            path: "/adm/planet/homework/homeworkList",
            name: "planetHomeworkList",
            component: (resolve) =>
              require(["@/adm/planet/homeworkList"], resolve),
          },
          {
            path: "/adm/planet/ask",
            name: "planetAsk",
            component: (resolve) => require(["@/adm/planet/ask"], resolve),
          },
          {
            path: "/adm/planet/member",
            name: "planetMember",
            component: (resolve) => require(["@/adm/planet/member"], resolve),
          },
          {
            path: "/adm/planet/data",
            name: "planetData",
            component: (resolve) => require(["@/adm/planet/data"], resolve),
          },
          {
            path: "/adm/planet/tags",
            name: "admPlanetTags",
            component: (resolve) => require(["@/adm/planet/tags"], resolve),
          },
          {
            path: "/adm/planet/menus",
            name: "admPlanetMenus",
            component: (resolve) => require(["@/adm/planet/menus"], resolve),
          },
          {
            path: "/adm/planet/setting",
            name: "planetSetting",
            component: (resolve) => require(["@/adm/planet/setting"], resolve),
          },
        ],
      },
      {
        path: "/adm/editPlanet",
        name: "admEditPlanet",
        component: (resolve) => require(["@/adm/planet/editPlanet"], resolve),
      },
      {
        path: "/adm/editCourse",
        name: "admEditCourse",
        component: (resolve) => require(["@/adm/planet/editCourse"], resolve),
      },
      {
        path: "/adm/setting/employee",
        name: "settingEmployee",
        component: (resolve) => require(["@/adm/setting/employee"], resolve),
      },
      {
        path: "/adm/setting/role",
        name: "settingRole",
        component: (resolve) => require(["@/adm/setting/role"], resolve),
      },
      {
        path: "/adm/analysis/order",
        name: "analysisOrder",
        component: (resolve) => require(["@/adm/analysis/order"], resolve),
      },
      {
        path: "/adm/analysis/renewal",
        name: "analysisRenewal",
        component: (resolve) => require(["@/adm/analysis/renewal"], resolve),
      },
      {
        path: "/adm/analysis/member",
        name: "analysisMember",
        component: (resolve) => require(["@/adm/analysis/member"], resolve),
      },
      {
        path: "/adm/analysis/content",
        name: "analysisContent",
        component: (resolve) => require(["@/adm/analysis/content"], resolve),
      },
      {
        path: "/adm/analysis/operate",
        name: "analysisOperate",
        component: (resolve) => require(["@/adm/analysis/operate"], resolve),
      },
      {
        path: "/adm/personalIncomeStatistic",
        name: "personalIncomeStatistic",
        component: (resolve) =>
          require(["@/views/personal/incomeStatistic"], resolve),
      },
      {
        path: "/adm/personalIncome",
        name: "personalIncome",
        component: (resolve) => require(["@/views/personal/income"], resolve),
      },
      {
        path: "/adm/personalIncomeDetail",
        name: "personalIncomeDetail",
        component: (resolve) => require(["@/views/personal/detail"], resolve),
      },
    ],
  },
  {
    path: "/adm/designer/index",
    name: "designer",
    component: (resolve) => require(["@/adm/designer/index"], resolve),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
