import axios from "@/utils/axios";
import { planet_clientkey } from "@/utils/config";

// 邮箱登录
export function mailLogin(params) {
  return axios.post("/api/v3/account/web/login_hybrid_center", params);
}

// 验证手机号注册
export async function checkPhoneExist(phone) {
  return axios.get("/api/v3/account/phone/exist", { phone });
}

// 获取手机验证码
export async function getPhoneVcode(phone) {
  // return axios.post(`/api/v3/account/phone/sendSms?phone=${phone}`);
  return axios.postJson("/api/v4/planet/web/login/sendSmsCode", {
    phoneNumber: phone,
  });
}

// 修改密码
export function updatePwd(params) {
  return axios.post("/api/v3/account/update/pwd", params);
}

// 抖音登录
export function accountDyLogin(data) {
  if (!data.clientKey) data.clientKey = planet_clientkey;
  return axios.post(`/api/v4/planet/web/login/tt-login`, data);
}

// 微信登录
export function accountWxLogin(data) {
  if (!data.clientKey) data.clientKey = planet_clientkey;
  return axios.post(`/api/v4/planet/web/login/wx-login`, data);
}

// 获取用户信息
export function getUserinfo(accessToken) {
  return axios.getLogin(`/api/v3/account/get_user_info`, accessToken);
}

export async function bindPhone(phone, code) {
  return axios.post(`/api/v3/account/bind/phone?phone=${phone}&vcode=${code}`);
}

// v4手机号登录
export function mobileLogin({ phoneNumber, validCode }) {
  return axios.postJson(`/api/v4/planet/web/login/mobileLogin`, {
    phoneNumber,
    validCode,
  });
}
