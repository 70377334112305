// export const planet_host = "https://star.jingyusiyu.com";
export const planet_host = "https://www.xingqiuhuiyuan.com";

export const planet_clientkey = "aw9mcdfz1orkkewj";
export const app = "tt_web_planet";

export const wx_planet_clientkey = "wxd84116057d8aed3f";
export const wx_app = "wx_web_planet";

export const tt_planet_appid = "tt183d6e808700c7f201";
export const wx_planet_appid = "wx72fd1c55084e7291";
export const h5_appid = "wxb697df37ee74f4e4"; // 人人讲讲师中心公众号appid
