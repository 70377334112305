import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loginWay: null, // wx dy phone
    normalLogin: true,
    user: null,
    token: null,
    planet: null,
    member: null,
    editTag: false,
    fromApp: false,
    updateMethod: "refresh",
  },
  getters: {
    getLoginWay: (state) => {
      return state.loginWay;
    },
    getNormalLogin: (state) => {
      return state.normalLogin;
    },
    getUser: (state) => {
      return state.user;
    },
    getToken: (state) => {
      return state.token;
    },
    getPlanet: (state) => {
      return state.planet;
    },
    getMember: (state) => {
      return state.member;
    },
    getEditTag: (state) => {
      return state.editTag;
    },
    getFromApp: (state) => {
      return state.fromApp;
    },
    getUpdateMethod: (state) => {
      return state.updateMethod;
    },
  },
  mutations: {
    setLoginWay(state, payload) {
      state.loginWay = payload.loginWay;
    },
    setNormalLogin(state, payload) {
      state.normalLogin = payload.normalLogin;
    },
    setUser(state, payload) {
      state.user = payload.user;
    },
    setToken(state, payload) {
      state.token = payload.token;
    },
    setPlanet(state, payload) {
      state.planet = payload.planet;
    },
    setMember(state, payload) {
      state.member = payload.member;
    },
    setEditTag(state, payload) {
      state.editTag = payload.editTag;
    },
    setFromApp(state, payload) {
      state.fromApp = payload.fromApp;
    },
    setUpdateMethod(state, payload) {
      state.updateMethod = payload.updateMethod;
    },
  },
  actions: {},
  modules: {},
});
