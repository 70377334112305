import axios from "axios"; // 引入axios
import QS from "qs"; // 引入qs模块，用来序列化post类型的数据
import { $rrj } from "@/utils/rrjutils";
const CancelToken = axios.CancelToken;
var service = axios.create({
  // timeout: 60000,
});
import { wx_app, wx_planet_clientkey } from "@/utils/config";

const baseParams = {
  app: wx_app,
  appid: wx_planet_clientkey,
};

export default {
  // baseURL: "https://api.renrenjiang.cn",
  baseURL(url) {
    console.log("url=" + url);
    if (!url.startsWith("/api/v4")) {
      return "https://api.renrenjiang.cn";
    } else {
      // return "http://localhost:8080";
      return "https://api.renrenjiang.cn";
    }
  },
  getLogin(url, Authorization) {
    url = this.baseURL(url) + url;
    let headers = {};
    headers.Authorization = Authorization;
    // console.log("_token", _token);
    return new Promise((resolve) => {
      service({
        method: "get",
        url,
        headers,
        cancelToken: new CancelToken(() => {}),
      })
        .then((res) => {
          //axios返回的是一个promise对象
          resolve(res.data); //resolve在promise执行器内部
        })
        .catch((err) => {
          console.log(err, "异常");
        });
    });
  },
  //get请求
  get(url, param) {
    url = this.baseURL(url) + url;
    let params = JSON.parse(JSON.stringify(param || {}));
    params = { ...params, ...baseParams };
    const _ukit = $rrj.storage.get("user");
    if (_ukit) {
      params.u = _ukit.id;
    }
    let headers = {};
    const _token = $rrj.storage.get("token");
    if (_token) {
      headers.Authorization = _token.id;
    }
    return new Promise((resolve) => {
      service({
        method: "get",
        url,
        headers,
        params: params,
        cancelToken: new CancelToken(() => {}),
      })
        .then((res) => {
          //axios返回的是一个promise对象
          resolve(res.data); //resolve在promise执行器内部
        })
        .catch((err) => {
          console.log(err, "异常");
        });
    });
  },
  //post请求
  post(url, param) {
    url = this.baseURL(url) + url;
    let params = JSON.parse(JSON.stringify(param || {}));
    params = { ...params, ...baseParams };
    const _ukit = $rrj.storage.get("user");
    if (_ukit) {
      params.u = _ukit.id;
    }
    let headers = {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    };
    const _token = $rrj.storage.get("token");
    if (_token) {
      headers.Authorization = _token.id;
    }

    // const _skit = storage.get("_skit");
    // if (_skit) {
    //   params.s = _skit.id;
    // }

    for (let i in params) {
      if (params[i] !== 0 && !params[i]) {
        delete params[i];
      }
    }

    return new Promise((resolve) => {
      service({
        method: "post",
        url,
        headers,
        data: QS.stringify(params, { indices: false }),
        cancelToken: new CancelToken(() => {}),
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err, "异常");
        });
    });
  },
  //post请求
  postBody(url, param) {
    url = this.baseURL(url) + url;
    let params = JSON.parse(JSON.stringify(param || {}));
    params = { ...params, ...baseParams };
    const _ukit = $rrj.storage.get("user");
    if (_ukit) {
      params.u = _ukit.id;
    }
    let headers = {
      "Content-Type": "application/json",
    };
    const _token = $rrj.storage.get("token");
    if (_token) {
      headers.Authorization = _token.id;
    }
    return new Promise((resolve) => {
      service({
        method: "post",
        url,
        headers,
        params: params,
        cancelToken: new CancelToken(() => {}),
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err, "异常");
        });
    });
  },
  //post JSON 请求
  postJson(url, param) {
    url = this.baseURL(url) + url;
    let params = JSON.parse(JSON.stringify(param || {}));
    params = { ...params, ...baseParams };
    const _ukit = $rrj.storage.get("user");
    if (_ukit) {
      params.u = _ukit.id;
    }
    let headers = {
      "Content-Type": "application/json",
    };
    const _token = $rrj.storage.get("token");
    if (_token) {
      headers.Authorization = _token.id;
    }
    return new Promise((resolve) => {
      service({
        method: "post",
        url,
        headers,
        data: JSON.stringify(params),
        cancelToken: new CancelToken(() => {}),
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err, "异常");
        });
    });
  },
  postForm(url, param) {
    url = this.baseURL(url) + url;
    let params = JSON.parse(JSON.stringify(param || {}));
    params = { ...params, ...baseParams };
    const _ukit = $rrj.storage.get("user");
    if (_ukit) {
      params.u = _ukit.id;
    }
    let headers = {
      "Content-Type": "application/json",
    };
    const _token = $rrj.storage.get("token");
    if (_token) {
      headers.Authorization = _token.id;
    }
    return new Promise((resolve) => {
      service({
        method: "post",
        url,
        headers,
        data: QS.stringify(params, { indices: false }),
        cancelToken: new CancelToken(() => {}),
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err, "异常");
        });
    });
  },
  filePost: function (url, param) {
    console.log(param, "param");
    // const params = JSON.parse(JSON.stringify(param || {}));
    url = this.baseURL(url) + url;
    // const _skit = storage.get("_skit");
    // if (_skit) {
    //   param.append("s", _skit.id);
    // }
    let headers = {
      "Content-Type": "multipart/form-data;charset=UTF-8",
    };
    const _token = $rrj.storage.get("token");
    if (_token) {
      headers.Authorization = _token.id;
    }
    return new Promise((resolve) => {
      service({
        method: "post",
        url,
        headers,
        data: param,
        cancelToken: new CancelToken(() => {}),
      })
        .then((res) => {
          console.log(res, "res---");
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err, "异常");
        });
    });
  },
  httpGet(url, param) {
    let params = JSON.parse(JSON.stringify(param || {}));
    params = { ...params, ...baseParams };
    const _ukit = $rrj.storage.get("user");
    if (_ukit) {
      params.u = _ukit.id;
    }
    let headers = {};
    const _token = $rrj.storage.get("token");
    if (_token) {
      headers.Authorization = _token.id;
    }
    return new Promise((resolve) => {
      service({
        method: "get",
        url,
        headers,
        params: params,
        cancelToken: new CancelToken(() => {}),
      })
        .then((res) => {
          //axios返回的是一个promise对象
          resolve(res.data); //resolve在promise执行器内部
        })
        .catch((err) => {
          console.log(err, "异常");
        });
    });
  },
};

// , { indices: false }
