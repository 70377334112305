/**
 *  格式化时间为YYYY-MM-DD HH:mm
 * @param {*} d 时间
 */
export function formatDateTime(d, s) {
  let fmt = "YYYY-MM-DD HH:mm";
  if (s) {
    fmt = s;
  }
  const t = new Date(d);
  const o = {
    "Y+": t.getFullYear(), // 年
    "M+": t.getMonth() + 1, // 月份
    "D+": t.getDate(), // 日
    "H+": t.getHours(), // 小时
    "m+": t.getMinutes(), // 分
    "s+": t.getSeconds(), // 秒
    "q+": Math.floor((t.getMonth() + 3) / 3), // 季度
    "S+": t.getMilliseconds(), // 毫秒
  };
  if (/(Y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (t.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return fmt;
}

// 检查图片大小
export function asyncImgChecked(file, w, h) {
  return new Promise((resolve) => {
    let reader = new FileReader();
    reader.readAsDataURL(file.raw); // 必须用file.raw
    reader.onload = () => {
      // 让页面中的img标签的src指向读取的路径
      let img = new Image();
      img.src = reader.result;
      if (img.complete) {
        // 如果存在浏览器缓存中
        if (img.width > w || img.height > h) {
          resolve(false);
        } else {
          resolve(true);
        }
      } else {
        img.onload = () => {
          if (img.width > w || img.height > h) {
            resolve(false);
          } else {
            resolve(true);
          }
        };
      }
    };
  });
}

// 复制到剪切板
export function copyToClipboard(text) {
  const input = document.createElement("input");
  input.value = text;
  document.body.appendChild(input);
  input.select();
  document.execCommand("copy");
  document.body.removeChild(input);
}

function doubleClick() {
  let bool = true;
  return function (fn, time = 1000) {
    if (bool) {
      fn();
      bool = false;
      setTimeout(() => {
        bool = true;
      }, time);
    }
  };
}

export const onceClick = doubleClick();
