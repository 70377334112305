import axios from "@/utils/axios";
import store from "../store";

function getData(data) {
  const formData = new FormData();
  const keys = Object.keys(data);
  for (let i = 0; i < keys.length; i++) {
    formData.append(keys[i], data[keys[i]]);
  }
  return formData;
}

const appid = 0; // 防报错
export function msgCheck({ content, apptype = appid }) {
  // #ifdef MP-WEIXIN
  // return axios.('/api/v3/system/msg_sec_check', {
  // 	content,
  // 	apptype
  // });
  // #endif
  console.log("msgCheck", content);
  console.log("msgCheck", apptype);
  return { errcode: 0 };
}

// 上传
export function uploadTemp(params) {
  return axios.filePost("/api/v2/server/upload_temp", params);
}

export function getPicInfo(pic) {
  // return new Promise((resolve, reject) => {
  //   axios.get({
  //     url: `${item}@info`,
  //     success(res) {
  //       console.log(res, "res");
  //       resolve({ ...res.data, content: item });
  //     },
  //   });
  // });

  return axios.httpGet(`${pic}@info`);
}

export function codeLogin({ code, app }) {
  return axios.post(`/api/v4/account/${appid}/login`, {
    code,
    app,
  });
}

export function wechatCodeLogin({ code, appid }) {
  return axios.get(`/api/v3/wechat/${appid}/wx_lite`, {
    include: "user,new",
    from: "wechat",
    way: "wechat",
    code: code,
  });
}

// 获取用户信息
export function getUserInfo({ userId }) {
  return axios.get(`/api/v3/users/${userId}/show`);
}

// 创建星球
export async function createPlanet({
  title,
  background,
  introduce,
  price,
  time_mode,
  time_unit,
  time_value,
  description,
  first_class,
  second_class,
}) {
  const res = await msgCheck({ content: title });
  if (res && res.errcode !== 87014) {
    return axios.post("/api/v4/planet/create", {
      title,
      background,
      introduce,
      price,
      time_mode,
      time_unit,
      time_value,
      description,
      first_class,
      second_class,
    });
  } else {
    return { code: -1, message: "操作失败，内容包含敏感词" };
  }
}

// 获取列表
export function getPlanetList({ pageSize, pageNum }) {
  return axios.get(`/api/v4/planet/findList`, {
    pageSize,
    pageNum,
  });
}

// 获取星球详情
export function planetShowDetail({ id }) {
  return axios.get(`/api/v4/planet/${id}/show`);
}

// 星球设置更新
export function planetUpdate({
  id,
  enable_share_theme,
  new_user_pubish,
  publish_theme_auth,
  open_member_list,
  enable_preview_theme,
  free_ask,
  comment_auth,
  anonymous_ask,
  ban_join,
}) {
  return axios.post(`/api/v4/planet/update`, {
    id,
    enable_share_theme,
    new_user_pubish,
    publish_theme_auth,
    open_member_list,
    enable_preview_theme,
    free_ask,
    comment_auth,
    anonymous_ask,
    ban_join,
  });
}

// 更新星球2
export function planetUpdate2({
  id,
  background,
  introduce,
  title,
  time_mode,
  time_unit,
  time_value,
  price,
  description,
  first_class,
  second_class,
  has_homepage,
  homepage_id,
}) {
  console.log(has_homepage, "has_homepage");
  return axios.post(`/api/v4/planet/update`, {
    id,
    background,
    introduce,
    title,
    time_mode,
    time_unit,
    time_value,
    price,
    description,
    first_class,
    second_class,
    has_homepage,
    homepage_id,
  });
}

// 获取加入的星球的列表
export function getJoinedPlanetList({ pageNum, pageSize, noDefault = 0 }) {
  return axios.get(`/api/v4/planet/findJoineds`, {
    pageNum,
    pageSize,
    noDefault,
  });
}

// 根据id列表获取星球列表
export function getPlanetListByIds({ ids }) {
  return axios.post(`/api/v4/planet/findPlanetByIds`, { ids });
}

// 是否就加入星球
export function hasJoinPlanet({ planetId, status = 1 }) {
  return axios.get(`/api/v4/planet/hasJoined`, { planetId, status });
}

// 加入星球
export function joinPlanet({ planetId, role = 1 }) {
  return axios.post(`/api/v4/planet/${planetId}/join`, { role });
}

// 踢人退款
export function planetMemberRefund({
  planet_id,
  user_id,
  operation_type,
  environment = 2,
}) {
  return axios.postJson(`/api/v4/planet/money/planetMemberRefund`, {
    planet_id,
    user_id,
    operation_type,
    environment,
  });
}

// 获取主题列表
export function getThemeList({
  pageNum,
  pageSize,
  planetId,
  status = 1,
  topicId = 1,
  filterTopicName,
  keyword,
  memberRole,
  memberNickName,
  startDate,
  endDate,
  targetId,
  target,
}) {
  return axios.get(`/api/v4/theme/findList`, {
    pageNum,
    pageSize,
    planetId,
    status,
    topicId,
    filterTopicName,
    keyword,
    memberRole,
    memberNickName,
    startDate,
    endDate,
    targetId,
    target,
  });
}

// 获取主题详情
export function getThemeDetail({ themeId }) {
  return axios.get(`/api/v4/theme/${themeId}/detail`);
}

export function getThemeDetail2({ target, targetId }) {
  return axios.get(`/api/v4/theme/detail`, {
    target,
    targetId,
  });
}

// 更新主题
export function deleteTheme({ themeId }) {
  return axios.post(`/api/v4/theme/${themeId}/delete`);
}

export function getTopicByName({ name, planetId }) {
  return axios.get(`/api/v4/planetTopic/detail`, { name, planetId });
}

// 添加标签并关联theme
export function addThemeTopic({ planet_id, theme_id, tag_name }) {
  return axios.postJson(`/api/v4/theme/addThemeTopic`, {
    planet_id,
    theme_id,
    tag_name,
  });
}

// 取消关联主题和话题
export function unRelativeTopic({ themeId, topicIds }) {
  return axios.post(`/api/v4/theme/unRelative/topic`, {
    themeId,
    topicIds,
  });
}

// 获取话题列表
export function getTopicList({
  pageNum,
  pageSize,
  planetId,
  userId,
  status,
  isDefault,
  ignore,
}) {
  return axios.get(`/api/v4/planetTopic/findList`, {
    pageNum,
    pageSize,
    planetId,
    userId,
    status,
    isDefault,
    ignore,
  });
}

// 搜素话题
export function searchTopic({ planetId, keyword, pageNum = 1, pageSize = 50 }) {
  return axios.get(`/api/v4/planetTopic/search`, {
    planetId,
    keyword,
    pageNum,
    pageSize,
  });
}

// 创建主题
export async function createTheme({
  planetId,
  content,
  status = 2,
  target,
  target_id,
}) {
  const res = await msgCheck({ content: content });
  if (res && res.errcode !== 87014) {
    return axios.postJson(`/api/v4/theme/create`, {
      planet_id: planetId,
      content,
      status,
      target,
      target_id,
    });
  } else {
    return { code: -1, message: "操作失败，内容包含敏感词" };
  }
}

// 更新主题，加精，置顶，删除
export async function updateTheme({
  id,
  top,
  highlight,
  status,
  userId,
  content,
  planetId,
  target,
}) {
  return axios.postJson(`/api/v4/theme/${id}/update`, {
    top,
    highlight,
    status,
    user_id: userId,
    content,
    planet_id: planetId,
    target,
  });
}

// 创建话题
export async function createTopic({ planetId, name, showTimeline, memberUse }) {
  const res = await msgCheck({ content: name });
  if (res && res.errcode !== 87014) {
    return axios.post(`/api/v4/planetTopic/create`, {
      planet_id: planetId,
      name,
      show_timeline: showTimeline,
      member_use: memberUse,
    });
  } else {
    return { code: -1, message: "操作失败，内容包含敏感词" };
  }
}

export async function updateTopic({
  topicId,
  planetId,
  name,
  showTimeline,
  memberUse,
}) {
  const res = await msgCheck({ content: name });
  if (res && res.errcode !== 87014) {
    return axios.post(`/api/v4/planetTopic/${topicId}/update`, {
      planet_id: planetId,
      name,
      show_timeline: showTimeline,
      member_use: memberUse,
    });
  } else {
    return { code: -1, message: "操作失败，内容包含敏感词" };
  }
}

export function deleteTopic({ topicId }) {
  return axios.post(`/api/v4/planetTopic/${topicId}/delete`);
}

// 获取话题的排序
export function updateTopicSort({ planetId, type, sortJson, status = 1 }) {
  store.commit("setEditTag", { editTag: true });
  return axios.post(`/api/v4/planetTopic/updateSort`, {
    type,
    status,
    planet_id: planetId,
    sort_json: sortJson,
  });
}

// 集体更新话题的status
export function updateTopicList({ topicIds, status }) {
  store.commit("setEditTag", { editTag: true });
  return axios.post(`/api/v4/planetTopic/list/update`, {
    topicIds,
    status,
  });
}

// 关联话题
export function relativeTopic({ themeId, topicIds }) {
  return axios.post(`/api/v4/theme/relative/topic`, {
    themeId,
    topicIds,
  });
}

// 获取成员列表
export function getMemberList({
  planetId,
  pageNum,
  pageSize,
  status = 1,
  roles,
}) {
  return axios.get(`/api/v4/planet/${planetId}/findMembers`, {
    planetId,
    pageNum,
    pageSize,
    status,
    roles,
  });
}

export function getMemberDetail({ memberId }) {
  return axios.get(`/api/v4/planet/${memberId}/memberDetail`);
}

export function getMemberDetailByUserId({ planetId, userId }) {
  return axios.get(`/api/v4/planet/memberDetailByUserId`, {
    planetId,
    userId,
  });
}

// export async function updateMember({ memberId, description }) {
//   const res = await msgCheck({ content: description });
//   if (res && res.errcode !== 87014) {
//     return axios.post(`/api/v4/planet/${memberId}/memberUpdate`, {
//       description,
//     });
//   } else {
//     return { code: -1, message: "操作失败，内容包含敏感词" };
//   }
// }
export async function updateMember({
  memberId,
  description = null,
  role = null,
  status = null,
}) {
  if (!description) {
    return axios.post(`/api/v4/planet/${memberId}/memberUpdate`, {
      role,
      status,
    });
  }
  const res = await msgCheck({ content: description });
  if (res && res.errcode !== 87014) {
    return axios.post(`/api/v4/planet/${memberId}/memberUpdate`, {
      description,
      role,
      status,
    });
  } else {
    return { code: -1, message: "操作失败，内容包含敏感词" };
  }
}

// 拉黑
export async function blockUser({ banUserId }) {
  return axios.get(`/api/v4/planet/blacklist/banUser`, {
    banUserId: banUserId,
  });
}

// 取消拉黑
export async function unblockUser({ banUserId }) {
  return axios.get(`/api/v4/planet/blacklist/delBanUser`, {
    banUserId: banUserId,
  });
}

// 获取成员角色列表
export function findLoginMember({ planetId }) {
  return axios.get(`/api/v4/planet/${planetId}/findLoginUserMember`);
}

// 创建问答
export async function createSpecial({
  planet_id,
  member_id,
  type,
  price,
  anonymous,
  title,
  content,
  time_range,
  ended_type,
  ended_at,
  show_type,
  plan_id,
}) {
  const res = await msgCheck({ content: title + content });
  if (res && res.errcode !== 87014) {
    return axios.post(`/api/v4/planetSpecial/create`, {
      planet_id,
      member_id,
      type,
      price,
      anonymous,
      title,
      content,
      time_range,
      ended_type,
      ended_at,
      show_type,
      plan_id,
    });
  } else {
    return { code: -1, message: "操作失败，内容包含敏感词" };
  }
}

export async function updateSpecial({
  id,
  status,
  planet_id,
  member_id,
  type,
  price,
  anonymous,
  title,
  content,
  time_range,
  ended_type,
  ended_at,
  show_type,
}) {
  const res = await msgCheck({ content: title + content });
  if (res && res.errcode !== 87014) {
    return axios.post(`/api/v4/planetSpecial/update`, {
      id,
      status,
      planet_id,
      member_id,
      type,
      price,
      anonymous,
      title,
      content,
      time_range,
      ended_type,
      ended_at,
      show_type,
    });
  } else {
    return { code: -1, message: "操作失败，内容包含敏感词" };
  }
}

export function deleteSpecial({ specialId }) {
  return axios.post(`/api/v4/planetSpecial/delete`, {
    id: specialId,
  });
}

export function getSpecialDetail({ id }) {
  return axios.get(`/api/v4/planetSpecial/detail`, {
    id,
  });
}

// 获取问答列表
export function specialFindList({
  pageNum,
  pageSize,
  planetId,
  type,
  punchcardType,
  title,
  planId,
}) {
  return axios.get(`/api/v4/planetSpecial/findList`, {
    planetId,
    type,
    pageNum,
    pageSize,
    punchcardType,
    title,
    planId,
  });
}

// 创建评论
export function createComment({
  theme_id,
  target,
  target_id,
  refer_userid,
  content,
}) {
  return axios.post(`/api/v4/planetComment/create`, {
    theme_id,
    target,
    target_id,
    refer_userid,
    content,
  });
}

// 更新评论
export function updateComment({
  id,
  theme_id,
  target,
  target_id,
  user_id,
  refer_userid,
  content,
  is_top,
  status,
}) {
  return axios.post(`/api/v4/planetComment/update`, {
    id,
    theme_id,
    target,
    target_id,
    user_id,
    refer_userid,
    content,
    is_top,
    status,
  });
}

// 获取评论列表
export function getCommentList({
  themeId,
  status,
  target,
  targetId,
  pageNum,
  pageSize,
}) {
  return axios.get(`/api/v4/planetComment/findList`, {
    themeId,
    status,
    target,
    targetId,
    pageNum,
    pageSize,
  });
}

// 置顶评论
export function topComment({ id, is_top }) {
  return axios.post(`/api/v4/planetComment/top`, { id, is_top });
}

// 删除评论
export function deleteComment({ id }) {
  return axios.post(`/api/v4/planetComment/del`, { id });
}

// 点赞
export function praise({ targetType, targetId }) {
  console.log("praise targetType", targetId);
  return axios.post(`/api/v4/planet/${targetType}/praise`, {
    targetId,
  });
}

// 取消点赞
export function cancelPraise({ targetType, targetId }) {
  return axios.post(`/api/v4/planet/${targetType}/cancelPraise`, {
    targetId,
  });
}

// 收藏
export function collect({ targetType, targetId }) {
  return axios.post(`/api/v4/planet/${targetType}/person/collect`, {
    targetId,
  });
}

// 取消收藏
export function cancelCollect({ targetType, targetId }) {
  return axios.post(`/api/v4/planet/${targetType}/person/cancelCollect`, {
    targetId,
  });
}

// 首页审核时推荐
export function getDouyinChannel() {
  // let token = uni.getStorageSync('token');

  // return new Promise((resolve, reject) => {
  // 	uni.request({
  // 		url: `https://api.renrenjiang.cn/api/v3/channels/doudian_channel/list2`,
  // 		data: { from: 'wx72fd1c55084e7291', way: 'wx72fd1c55084e7291', u: token.userId, app: 'wx_planet_jy',appId: 'wx72fd1c55084e7291' },
  // 		method: 'GET',
  // 		header: {
  // 		'Content-Type': 'application/json',
  // 		'Authorization': token.id
  // 	},

  // 		success(res) {
  // 			resolve(res.data);
  // 		},

  // 		fail(err) {
  // 			reject(err);
  // 		}

  // 	});
  // });
  return axios.get(`/api/v3/channels/doudian_channel/list2`);
}

// 首页审核时推荐
export function findChannelRecommend(params) {
  return axios.get(`/api/v3/column_hybrid/find_channel_recommend`, {
    ...params,
  });
}

// 获取开关
export function getSwitches(tags) {
  // let token = uni.getStorageSync('token');

  // return new Promise((resolve, reject) => {
  // 	uni.request({
  // 		url: `https://api.renrenjiang.cn/api/v3/system/switch?include=${tags}`,
  // 		data: { from: 'wx72fd1c55084e7291', way: 'wx72fd1c55084e7291', u: token.userId, app: 'wx_planet_jy',appId: 'wx72fd1c55084e7291' },
  // 		method: 'GET',
  // 		header: {
  // 		'Content-Type': 'application/json',
  // 		'Authorization': token.id
  // 	},

  // 		success(res) {
  // 			resolve(res.data);
  // 		},

  // 		fail(err) {
  // 			reject(err);
  // 		}

  // 	});
  // });
  return axios.get(`/api/v3/system/switch?include=${tags}`);
}

// 获取手机号
export function getPhone(data) {
  return axios.get(`/api/v3/tt/ttlite2/decrypt`, { ...data });
}

// 给付费星球创建课程
export function createCourse(params) {
  return axios.postJson(`/api/v4/planet/create-course`, params);
}

// 星球课程审核失败，重新编辑
export function editCourse(editParams) {
  return axios.postJson(`/api/v4/planet/edit-course`, editParams);
}

// 查询课程分类
export function getAllCourseCategories() {
  return axios.get(`/api/v4/planet/getAllCourseCategories`);
}

export function getAllCourseCategories2() {
  return axios.get(`/api/v4/planet/getAllCourseCategories2`);
}

// 根据星球的ID查询课程（planet_column表）列表
export function findColumnsByPlanetId({ planetId, pageNum, pageSize }) {
  return axios.get(`/api/v4/planetColumn/findColumnsByPlanetId`, {
    planetId,
    pageNum,
    pageSize,
  });
}

// 创建专栏
export function createPlanetColumn({ planet_id, title }) {
  return axios.post(`/api/v4/planetColumn/create`, {
    planet_id,
    title,
  });
}

// 更新专栏
export function updatePlanetColumn({ id, title = null, status = null }) {
  return axios.post(`/api/v4/planetColumn/${id}/update`, {
    title,
    status,
  });
}

// 专栏详情
export function getPlanetColumnDetail({ id }) {
  return axios.get(`/api/v4/planetColumn/${id}/detail`);
}

// 删除专栏
export function deletePlanetColumn({ id }) {
  return axios.post(`/api/v4/planetColumn/${id}/delete`);
}

// 获取专栏列表
export function getPlanetColumnList({
  planetId,
  pageNum,
  pageSize,
  status,
  themeId,
  title,
}) {
  return axios.get(`/api/v4/planetColumn/findList`, {
    planetId,
    pageNum,
    pageSize,
    status,
    themeId,
    title,
  });
}

// 获取专栏holder详情
export function getPlanetColumnHolderDetail({ planetId, status }) {
  return axios.get(`/api/v4/planetColumn/columnHolderDetail`, {
    planetId,
    status,
  });
}

// 更新专栏holder
export function updatePlanetColumnHolder({ id, name }) {
  return axios.post(`/api/v4/planetColumn/columnHolderUpdate`, {
    id,
    name,
  });
}

// 专栏增加theme
export function columnAddTheme({ columnId, themeId }) {
  return axios.post(`/api/v4/planetColumn/add/theme`, {
    columnId,
    themeId,
  });
}

// 专栏集体增加theme
export function groupColumnsAddTheme({ columnIds, themeId }) {
  return axios.post(`/api/v4/planetColumn/group/add/theme`, {
    columnIds,
    themeId,
  });
}

// 专栏去除theme
export function columnRemoveTheme({ columnId, themeId }) {
  return axios.post(`/api/v4/planetColumn/remove/theme`, {
    columnId,
    themeId,
  });
}

// 专栏集体去除theme
export function groupColumnsRemoveTheme({ columnIds, themeId }) {
  return axios.post(`/api/v4/planetColumn/group/remove/theme`, {
    columnIds,
    themeId,
  });
}

// 查询专栏中的theme
export function columnFindTheme({ columnId, pageNum, pageSize, order }) {
  return axios.get(`/api/v4/planetColumn/findThemeList`, {
    columnId,
    pageNum,
    pageSize,
    order,
  });
}

// 从一个专栏中移除多个theme
export function columnRemoveThemes({ columnId, themeIds }) {
  return axios.post(`/api/v4/planetColumn/remove/themes`, {
    columnId,
    themeIds,
  });
}

// 获取作业列表
export function findHomeworkList({
  pageNum,
  pageSize,
  planetId,
  status = 2,
  targetId,
  themeId,
  order,
}) {
  return axios.get(`/api/v4/theme/findHomeworkList`, {
    pageNum,
    pageSize,
    planetId,
    status,
    targetId,
    themeId,
    order,
  });
}

// 获赞排行榜
export function findHomeworkGetLikeRank({
  themeId,
  targetId,
  pageNum,
  pageSize,
}) {
  return axios.get("/api/v4/theme/findHomeworkGetLikeRank", {
    themeId,
    targetId,
    pageNum,
    pageSize,
  });
}

// 提交作业的普通用户和普通用户总数
export function findHomeworkStatistic({ themeId }) {
  return axios.get("/api/v4/theme/findHomeworkStatistic/" + themeId);
}

// 查询星球的作业列表
export function findHomeworkRulesList({ planetId, status, content }) {
  return axios.get("/api/v4/theme/findHomeworkRulesList", {
    planetId,
    status,
    content,
  });
}

// 获取鲸鱼私域的群聊列表
// pageNum=1&pageSize=10&total=4&status=1&page=1&u=37228941&s=67
export function getGroupList({ pageNum, pageSize, status = 1, s }) {
  return axios.get("/api/v4/channel/group/findList", {
    pageNum,
    pageSize,
    status,
    s,
  });
}

// 我的 - 基础信息
export function getBaseStatisticInfo() {
  return axios.get("/api/v4/planet/mine/getBaseStatisticInfo", {});
}

// 我的 - 我的足迹
export function footInfoFindList({ planetId, pageNum, pageSize }) {
  return axios.get("/api/v4/planet/mine/footInfoFindList", {
    planetId,
    pageNum,
    pageSize,
  });
}

// 我的 - 我加入的星球
export function minePlanetListInfo({ pageNum, pageSize }) {
  return axios.get("/api/v4/planet/mine/minePlanetListInfo", {
    pageNum,
    pageSize,
  });
}

// 我的提问
export function getMineAnswer() {
  return axios.get("/api/v4/planet/mine/findMyAnswerList");
}

// 我的回答
export function getMineAsk() {
  return axios.get("/api/v4/planet/mine/findMyAskList");
}

// 我的收藏
export function getMyCollection() {
  return axios.get("/api/v4/planet/mine/findMyCollectList");
}

// 灵活用工查询审核结果
export function getApplyResult() {
  return axios.get(`/api/v3/user2/identity/info`);
}

// 查询钱包
export function getMyWallet() {
  return axios.get("/api/v4/planet/money/findPlanetWallet");
}

// 申请提现
export function applyWithdraw({ amount, channel }) {
  return axios.post("/api/v4/planet/money/userWithdraw", { amount, channel });
}

// 编辑资料-查询审核状态
export function findCourseAudit({ planetId }) {
  return axios.get(`/api/v4/planet/findCourseAudit`, { planetId });
}

// 灵活用工-申请
// 云账户银行卡四要素验证
export function verifyUserFourFactor({ realname, id_card, card_no, mobile }) {
  // 参数： realname， id_card， card_no（银行卡号）， mobile
  return axios.post(`/api/v3/user2/linghuo/verify_four_factor`, {
    realname,
    id_card,
    card_no,
    mobile,
  });
}

// 获取云账户签约链接
export function getUserSignURL(params) {
  // realname， id_card， card_no（银行卡号）， mobile， redirect_url(签约完成重定向回来的页面地址)
  return axios.get(`/api/v3/user2/linghuo/h5_sign_url`, params);
}

// 获取最新的订单
export function getLatestOrder() {
  return axios.get("/api/v4/planet/money/findLastSaasOrder");
}

// 获取适合产品
export function getTrailGoods() {
  return axios.get("/api/v4/planet/money/findSaasTrialGoods");
}

// 获取所有的saas产品
export function getAllSaasGoods({ status }) {
  return axios.get("/api/v4/planet/money/findSaasGoodsList", {
    status,
  });
}

// pin++ native支付 saas产品
export function pinppNativePay({
  product_id,
  channel = "wx_pub_qr",
  environment = 2,
}) {
  return axios.postJson("/api/v4/planet/money/pingPPBuySass", {
    product_id,
    channel,
    environment,
  });
}

// 根据订单号查询订单详情
export function getSaasOrderDetail({ orderNo }) {
  return axios.get("/api/v4/planet/money/findSaasOrderDetail", {
    orderNo,
  });
}

// 发送验证码
export function sendVerifyCode({ phoneNumber }) {
  return axios.postJson("/api/v4/planet/web/login/sendSmsCode", {
    phoneNumber,
  });
}

// 获取当前账号绑定的手机号
export function getBindMobile() {
  return axios.get("/api/v4/planet/web/login/validUserBindPhoneStatus");
}

// 绑定手机号
export function bindMobile({ phoneNumber, validCode }) {
  return axios.postJson("/api/v4/planet/web/login/bindPhone", {
    phoneNumber,
    validCode,
  });
}

// 更改手机号
export function changePhone({ phoneNumber, validCode }) {
  return axios.postJson("/api/v4/planet/web/login/changePhone", {
    phoneNumber,
    validCode,
  });
}

// 优惠券统计
export function getDataOverview(param) {
  return axios.get("/api/v4/coupon/dataOverview", param);
}

// 优惠券分页列表
export function getCouponsPageList(
  param = { pageNum: 1, pageSize: 10, planet_id: 1 }
) {
  return axios.get("/api/v4/coupon/couponsPageList", param);
}

// 根据优惠券id查询优惠券信息
export function gefindCouponForPay(param = { couponId: 1 }) {
  return axios.get("/api/v4/coupon/findCouponForPay", param);
}

// 创建优惠券
export function createCoupon(param) {
  console.log(param, "param");
  return axios.postJson("/api/v4/coupon/createCoupon", param);
}

// 停止优惠券
export function postStopCoupon(param) {
  return axios.postJson("/api/v4/coupon/stopCoupon", param);
}

// 根据星球id查询星球信息
export function getfindPlanetplanetsById(param) {
  return axios.get("/api/v4/planet/findPlanetplanetsById", param);
}

// 新增新人券-查询特定星球
export function getfindPlanetsForCoupon(param) {
  return axios.get("/api/v4/planet/findPlanetsForCoupon", param);
}

// 获取星球续期用户数量
export function getPlanetRenewalUserCount({ planetId }) {
  return axios.get("/api/v4/coupon/orderExpireCount", {
    planetId,
  });
}

// 查询订单列表
export function getOrderList({
  type,
  pay_status,
  refund_status,
  order_id,
  planet_id,
  start_at,
  end_at,
  pageNum,
  pageSize,
}) {
  return axios.get("/api/v4/planet/money/findOrderList", {
    type,
    pay_status,
    refund_status,
    order_id,
    planet_id,
    start_at,
    end_at,
    pageNum,
    pageSize,
  });
}

// 查询足迹列表
export function getFootPrintList({ pageNum, pageSize, userId, planetUserId }) {
  return axios.get("/api/v4/planet/member/someoneFootInfoList", {
    pageNum,
    pageSize,
    userId,
    planetUserId,
  });
}

// 查询我的足迹
export function getMyFootPrintList({ pageNum, pageSize, planetId = 0 }) {
  return axios.get("/api/v4/planet/mine/footInfoFindList", {
    pageNum,
    pageSize,
    planetId,
  });
}

// 获取短连接
export function getShortUrl({ longUrl }) {
  return axios.get("/api/v4/planet/shortUrl", {
    longUrl,
  });
}

// 修改个人信息
export function updateUser({ id, nickname, avatar_url, description }) {
  return axios.post("/api/v3/users/update", {
    id,
    nickname,
    avatar_url,
    description,
  });
}

// 星球列表详情页数据
export function getPlanetBaseData({ planetId }) {
  return axios.get(`/api/v4/planet/web/statistic/admOnePlanetBaseData`, {
    planetId,
  });
}

// 星球列表详情页折线图
export function getPlanetDetailsChart({ planetId, startDate, endDate, type }) {
  return axios.get(`/api/v4/planet/web/statistic/admOnePlanetChartData`, {
    planetId,
    startDate,
    endDate,
    type,
  });
}

// 交易基本数据统计
export function getAdmTransBaseData() {
  return axios.get("/api/v4/planet/web/statistic/admTransBaseData");
}

// 交易转化率统计
export function getConvertRateData({ startDate, endDate }) {
  return axios.get("/api/v4/planet/web/statistic/admTransConvertRateData", {
    startDate,
    endDate,
  });
}

// 交易收入数据/交易人数数据
export function getTransIncomeChartData({ startDate, endDate, type }) {
  return axios.get("/api/v4/planet/web/statistic/admTransIncomeChartData", {
    startDate,
    endDate,
    type,
  });
}

// 续期分析-将来12个月过期成员
export function getWillEndUserCountStatistic() {
  return axios.get("/api/v4/planet/web/statistic/admWillEndUserCountStatistic");
}

// 续期分析-基本数据统计
export function getRenewTransBaseData() {
  return axios.get("/api/v4/planet/web/statistic/admRenewTransBaseData");
}

// 交易收入数据/交易人数数据
/* eslint-disable */
export function getRenewTransConvertRateData({ startDate, endDate }) {
  return axios.get(
    "/api/v4/planet/web/statistic/admRenewTransConvertRateData",
    {
      startDate,
      endDate,
    }
  );
}

/* eslint-enable */

// 成员活跃-基本信息统计
export function getMemberActiveBaseData() {
  return axios.get("/api/v4/planet/web/statistic/admMemberActiveBaseData");
}

// 成员活跃-活跃成员数据
export function getMemberActiveChartData({ startDate, endDate }) {
  return axios.get("/api/v4/planet/web/statistic/admMemberActiveChartData", {
    startDate,
    endDate,
  });
}

// 内容活跃-基本数据统计
export function getContentActiveBaseData() {
  return axios.get("/api/v4/planet/web/statistic/admContentActiveBaseData");
}

// 内容活跃-成员互动数据
export function getContentActiveChartData({ startDate, endDate }) {
  return axios.get("/api/v4/planet/web/statistic/admContentActiveChartData", {
    startDate,
    endDate,
  });
}

// 用户概况-基本数据统计
export function getUserSituationBaseData({ planetId }) {
  return axios.get("/api/v4/planet/web/statistic/admUserSituationBaseData", {
    planetId,
  });
}

// 分片上传相关
export function partUploadStart({ suffix }) {
  return axios.post("/api/v4/partUpload/init", {
    suffix,
  });
}

// 正式开始上传
export function partUpload({ partNumber, uploadId, inputStream }) {
  return axios.postJson("/api/v4/partUpload/upload", {
    partNumber,
    uploadId,
    inputStream,
  });
}

// 上传结束
export function partUploadEnd({ uploadId }) {
  return axios.postJson("/api/v4/partUpload/complete", {
    uploadId,
  });
}

// 推广渠道-编辑星球
export function planetUpdate3(obj) {
  return axios.post(`/api/v4/planet/update`, obj);
}

// 推广渠道-提交抖音审核
export function submitProcess({ planet_id }) {
  return axios.postJson("/api/v4/planet/startAudit", {
    planet_id,
  });
}

// 推广渠道-重新提交抖音审核
export function retryAudit({ planet_id }) {
  return axios.postJson("/api/v4/planet/retryAudit", {
    planet_id,
  });
}

// 创建打卡计划
export function createPunchcardPlan({
  planet_id,
  user_id,
  title,
  content,
  punchcard_daily_limit,
  punchcard_time_limit,
  start_at,
  end_at,
}) {
  return axios.post("/api/v4/planet/punchcard/createPlan", {
    planet_id,
    user_id,
    title,
    content,
    punchcard_daily_limit,
    punchcard_time_limit,
    start_at,
    end_at,
  });
}

// 更新打卡计划
export function updatePunchcardPlan({
  id,
  planet_id,
  user_id,
  title,
  content,
  punchcard_daily_limit,
  punchcard_time_limit,
  start_at,
  end_at,
}) {
  return axios.post("/api/v4/planet/punchcard/updatePlan", {
    id,
    planet_id,
    user_id,
    title,
    content,
    punchcard_daily_limit,
    punchcard_time_limit,
    start_at,
    end_at,
  });
}

// 删除打卡计划
export function deletePunchcardPlan({ id, planet_id, user_id }) {
  return axios.post("/api/v4/planet/punchcard/deletePlan", {
    id,
    planet_id,
    user_id,
  });
}

// 停止打卡计划
export function stopPunchcardPlan({ id, planet_id, user_id }) {
  return axios.post("/api/v4/planet/punchcard/stopPlan", {
    id,
    planet_id,
    user_id,
  });
}

// 获取打卡计划详情
export function getPunchcardPlanDetail({ planId }) {
  return axios.get("/api/v4/planet/punchcard/planDetail", {
    planId,
  });
}

// 获取打卡计划列表
export function getPunchcardPlanList({
  planet_id,
  user_id = null,
  step_status = null,
  status = 1,
  keyword = null,
}) {
  return axios.get("/api/v4/planet/punchcard/findList", {
    planet_id,
    user_id,
    step_status,
    status,
    keyword,
  });
}

// 移动video在OSS的位置
export function moveOss({ url }) {
  return axios.post("/api/v4/partUpload/moveOss", {
    url,
  });
}

// 获取课程列表
export function getColumnList({
  planetId,
  pageNum,
  pageSize,
  user_id,
  title = undefined,
}) {
  return axios.get(`/api/v4/stream/column/findList2`, {
    target_id: planetId,
    pageNum,
    pageSize,
    user_id,
    title,
  });
}

export function createPlanetCourse(data) {
  return axios.post(`/api/v4/stream/column/create`, data);
}

export function updatePlanetCourse(data) {
  return axios.post(`/api/v4/stream/column/update`, data);
}

// 获取合集详情
export function getColumnDetail({ id }) {
  return axios.get(`/api/v4/stream/column/detail2`, {
    id: id,
  });
}

export function enableMountScope(id, userId, openid, scopeList) {
  return axios.post(
    `/api/v4/planet/enableMountScope?id=${id}&userId=${userId}&openid=${openid}&scopeList=${scopeList}`
  );
}

export function reableMountScope(id, scopeList) {
  return axios.post(
    `/api/v4/planet/reableMountScope?id=${id}&scopeList=${scopeList}`
  );
}

export function findMountScope() {
  return axios.get(`/api/v4/planet/findMountScope`);
}

export function findMountVideoList(data) {
  return axios.get(`/api/v4/dy/video/findList`, data);
}

export function bindXcxPage(data) {
  return axios.post(`/api/v4/dy/video/bindXcxPage`, data);
}

export function unbindXcxPage(data) {
  return axios.get(`/api/v4/dy/video/unbindXcxPage`, data);
}

export function getMountScopeList({ pageNum, pageSize }) {
  return axios.get("/api/v4/planet/getMountScopeList", {
    pageNum,
    pageSize,
  });
}

// 同步授权状态
export function asyncMountScope(id) {
  return axios.post(`/api/v4/planet/asyncMountScope?id=${id}`);
}

// 解绑并且删除
export function deleteMountScope(id) {
  return axios.post(`/api/v4/planet/deleteMountScope?id=${id}`);
}

export function getXcxUserAuth(awemeId, openid) {
  return axios.get(
    `/api/v4/dy/video/getXcxUserAuth?awemeId=${awemeId}&openid=${openid}`
  );
}

export function systemSwitch(data) {
  return axios.get(`/api/v3/system/switch`, data);
}

export function initTTAccountRecord() {
  return axios.post("/api/v4/planet/initTTAccountRecord");
}

export function getAccountRecordById(id) {
  return axios.get(`/api/v4/planet/getAccountRecordById?id=${id}`);
}

export function updateAccountRecordById(data) {
  return axios.post(`/api/v4/planet/updateAccountRecordById`, data);
}

// 获取抖音群
export function getDouyinGroupList({ pageNum, pageSize, group_name, openid }) {
  return axios.get("/api/v4/planet/dy/fans/group/findFansGroupList", {
    pageNum,
    pageSize,
    group_name,
    openid,
  });
}

// 同步抖音群
export function asyncDouyinGroupList() {
  return axios.get("/api/v4/planet/dy/fans/group/syncFansGroup");
}

// 微信群列表
export function findWxGroupList(data) {
  return axios.get("/api/v4/wechat/group/findList", data);
}

export function addWxGroup(data) {
  return axios.post("/api/v4/wechat/group/addGroup", data);
}

export function updateWxGroup(data) {
  return axios.post("/api/v4/wechat/group/updateGroup", data);
}

export function delWxGroup(data) {
  return axios.get("/api/v4/wechat/group/delGroup", data);
}

// 绑定群
export function bindPlanetAndGroup(data) {
  return axios.post(`/api/v4/planet/dy/fans/group/bindPlanetAndGroup`, data);
}

export function unbindPlanetAndGroup(data) {
  return axios.post(`/api/v4/planet/dy/fans/group/unbindPlanetAndGroup`, data);
}

// 根据openid查询可以建群的数量
export function getLeftGroupNum(openid) {
  return axios.get(
    `/api/v4/planet/dy/fans/group/getCanCreateGroupNum?open_id=${openid}`
  );
}

// 上传抖音的群头像
export function uploadDouyinGroupAvatar(file) {
  return axios.filePost(
    "/api/v4/planet/dy/fans/group/uploadGroupAvatar",
    getData(file)
  );
}

// 创建群
export function createDouyinGroup(data) {
  return axios.post("/api/v4/planet/dy/fans/group/createFansGroup", data);
}

// 更新抖音群的规则开关
export function updateDouyiFansGroup(
  id,
  planet_user_auto_into,
  not_planet_user_auto_into
) {
  return axios.post(
    `/api/v4/planet/dy/fans/group/updateFansGroup?id=${id}&planet_user_auto_into=${planet_user_auto_into}&not_planet_user_auto_into=${not_planet_user_auto_into}`
  );
}

// 配置短信
export function setMessageRules(data) {
  return axios.post("/api/v4/planet/utils/setMessageRules", data);
}

// 绑定微信群
export function bindWxPlanetAndGroup(data) {
  return axios.post(`/api/v4/wechat/group/bindPlanetAndGroup`, data);
}

export function unbindWxPlanetAndGroup(data) {
  return axios.post(`/api/v4/wechat/group/unbindPlanetAndGroup`, data);
}

export function convertWechatQrcode(data) {
  return axios.get(`/api/v4/wechat/group/convertWechatQrcode`, data);
}

export function getMessageRules(data) {
  return axios.post(`/api/v4/planet/utils/getMessageRules`, data);
}

export function getXcxHoldCodeByOpenId(data) {
  return axios.get(`/api/v4/dy/video/getXcxHoldCodeByOpenId`, data);
}

export function xcxOauthAccessToken(data) {
  return axios.get(`/api/v4/dy/video/xcxOauthAccessToken`, data);
}

// 获取授权列表
export function findAuthList(data) {
  return axios.get(
    "/api/v4/planet/teacher/qualification/findAuthEntityBypartnerList",
    data
  );
}

// 提交资质授权
export function togetherAuth(data) {
  return axios.postJson(
    "/api/v4/planet/teacher/qualification/togetherAuth",
    data
  );
}

// 更新授权小程序的信息
export function personalEntityUpdateAuthletter(data) {
  return axios.postJson(
    "/api/v4/planet/teacher/qualification/personalEntityUpdateAuthletter",
    data
  );
}

// 更新基础信息
export function personalEntityUpdateBasicAuth(data) {
  return axios.post(
    "/api/v4/planet/teacher/qualification/personalEntityUpdateBasicAuth",
    data
  );
}

// 更新类目信息
export function personalEntityUpdateClassAuth(data) {
  return axios.postJson(
    "/api/v4/planet/teacher/qualification/personalEntityUpdateClassAuth",
    data
  );
}

export function visitor_create(data) {
  return axios.post(`/api/v3/teacher_hybrid/visitor_create`, data);
}
