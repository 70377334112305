import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import rrjutils from "./utils/rrjutils";
Vue.use(rrjutils);

Vue.config.productionTip = false;

import "@/permission"; // permission control

import ElementUI from "element-ui";
import "./assets/css/theme/index.css";
import "@/assets/css/base.scss";
Vue.use(ElementUI);

import axios from "./utils/axios";
Vue.prototype.axios = axios;

import GLOBAL from "./global";
Vue.prototype.$global = GLOBAL;

// 剪贴板
import VueClipBoard from "vue-clipboard2";
Vue.use(VueClipBoard);

Vue.mixin({
  filters: {
    avatarFilter(user) {
      if (user && user.avatar_url && user.avatar_url.startsWith("http")) {
        return user.avatar_url.replace(
          "https://renrenjiang-stage.oss-cn-hangzhou.aliyuncs.com",
          "https://image.renrenjiang.cn"
        );
      } else if (user && user.avatar && user.avatar.startsWith("http")) {
        return user.avatar.replace(
          "https://renrenjiang-stage.oss-cn-hangzhou.aliyuncs.com",
          "https://image.renrenjiang.cn"
        );
      } else if (user && user.avatar_url) {
        return `https://image.renrenjiang.cn/uploads/user/avatar_url/${user.id}/${user.avatar_url}`;
      } else {
        return "https://image.renrenjiang.cn/assets/avatar_jy.jpg";
      }
    },
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this;
  },
}).$mount("#app");

Vue.prototype.base64ToFile = function (dataurl, filename = "file") {
  let arr = dataurl.split(",");
  let mime = arr[0].match(/:(.*?);/)[1];
  // suffix是该文件的后缀
  let suffix = mime.split("/")[1];
  // atob 对经过 base-64 编码的字符串进行解码
  let bstr = atob(arr[1]);
  // n 是解码后的长度
  let n = bstr.length;
  // Uint8Array 数组类型表示一个 8 位无符号整型数组 初始值都是 数子0
  let u8arr = new Uint8Array(n);
  // charCodeAt() 方法可返回指定位置的字符的 Unicode 编码。这个返回值是 0 - 65535 之间的整数
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  // new File返回File对象 第一个参数是 ArraryBuffer 或 Bolb 或Arrary 第二个参数是文件名
  // 第三个参数是 要放到文件中的内容的 MIME 类型
  return new File([u8arr], `${filename}.${suffix}`, {
    type: mime,
  });
};

Vue.prototype.getFileBase64 = async function (file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    let fileResult = "";
    reader.readAsDataURL(file);
    //开始转
    reader.onload = function () {
      fileResult = reader.result;
    };
    //转 失败
    reader.onerror = function (error) {
      reject(error);
    };
    //转 结束  咱就 resolve 出去
    reader.onloadend = function () {
      resolve(fileResult);
    };
  });
};

/**
 * @param {Object} str 需要脱敏的字符串
 * @param {Object} begin 前面保留位数
 * @param {Object} end 后面保留位数
 */
Vue.prototype.desensitized = function (str, begin, end) {
  if (!str && begin + end >= str.length) {
    return "";
  }

  let leftStr = str.substring(0, begin);
  let rightStr = str.substring(str.length - end, str.length);

  let strCon = "";
  for (let i = 0; i < str.length - end - begin; i++) {
    strCon += "*";
  }
  return leftStr + strCon + rightStr;
};

import { formatDateTime } from "@/utils/index";

/**
 * 生成UUID
 */
Vue.prototype.uuid = function () {
  var s = [];
  var hexDigits = "0123456789abcdef";
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "";

  var uuid = s.join("");
  return uuid;
};

// 全局注册一个日期格式化过滤器
Vue.filter("formatDateTime", function (d, s) {
  if (!d) return "-";
  return formatDateTime(d, s);
});

/**
 * 字节数格式化
 * @returns
 */
Number.prototype.formatsize = function () {
  if (this === null || this === 0) return "0 B";
  const k = 1024;
  const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(this) / Math.log(k));
  return (this / Math.pow(k, i)).toPrecision(3) + " " + sizes[i];
};

Array.prototype.swap = function (index1, index2) {
  this[index1] = this.splice(index2, 1, this[index1])[0];
  return this;
};

Number.prototype.numberToCurrency = function () {
  if (!this) return "";
  // 获取整数部分
  const intPart = Math.trunc(this);
  // 整数部分处理，增加,
  const intPartFormat = intPart
    .toString()
    .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
  // 预定义小数部分
  let floatPart = "";
  // 将数值截取为小数部分和整数部分
  const valueArray = this.toString().split(".");
  if (valueArray.length === 2) {
    // 有小数部分
    floatPart = valueArray[1].toString(); // 取得小数部分
    return intPartFormat + "." + floatPart;
  }
  return intPartFormat + floatPart;
};

Date.prototype.format = function (fmt) {
  var o = {
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "H+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  return fmt;
};

/**
 * 生成UUID
 */
Vue.prototype.uuid = function () {
  var s = [];
  var hexDigits = "0123456789abcdef";
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "";

  var uuid = s.join("");
  return uuid;
};
